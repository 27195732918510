<router-outlet></router-outlet>


<p-toast [baseZIndex]="5000" class="edw-toast" [style]="{marginTop: '60px'}">
  <ng-template let-message pTemplate="message">
    <div class="align-items_center">
      <div class="flex align-items_center w100">
        <i class="edw-icon edw-icon-info font20 m-r-5"></i>
        <h3 class="title-toast m-b-5">{{message.summary|translate}}</h3>
      </div>
      <p class="desc-toast">{{message.detail|translate}}</p>
    </div>
  </ng-template>
</p-toast>
